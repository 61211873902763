import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

const $parent = document.getElementById("i18n")
export const i18n = new VueI18n({
  locale: "en",
  messages: {
    en: {
      market: $parent ? JSON.parse($parent.dataset.market) : {},
      hours: "less than an hour | {n} hour | {n} hours",
    },
  },
})
